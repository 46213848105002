<template>
	<div>
		<!-- show userImage-->
		<div class="relative flex flex-col justify-center">
			<NuxtImg
				v-if="user.images"
				:src="user.images.small"
				:class="[
					{
						'cursor-pointer': !viewOnly,
						'rounded-full': roundedImage,
						borderColor: borderColor !== 'none',
						'border-2 border-solid': borderColor !== 'none'
					},
					borderColor,
					[smaller ? 'w-16 h-16' : 'w-32 h-32']
				]"
				class="profile-image object-cover mx-auto"
				@click="$emit('click-img')"
			/>
			<slot name="edit" />
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	emits: ['click-img'],
	computed: {
		borderColor(): string {
			switch (this.color) {
				case 'bg-color-blue-grey':
					return 'border-color-blue-grey';
				case 'bg-color-blue-hover':
					return 'border-color-blue-hover';
				case 'bg-color-text-lighter':
					return 'border-color-text-lighter';
				case 'bg-color-main-business-hover':
					return 'border-color-main-business-hover';
				case 'bg-color-blue':
					return 'border-color-blue';
				case 'bg-color-white':
					return 'border-color-white';
				case 'transparent':
					return 'border-transparent';
				case 'none':
					return 'none';
				default:
					return 'border-color-main';
			}
		}
	},
	props: {
		viewOnly: { type: Boolean, default: false },
		smaller: { type: Boolean, default: false },
		user: { type: Object, required: true },
		color: { type: String, required: false },
		roundedImage: { type: Boolean, default: true }
	}
});
</script>

<style lang="scss" scoped>
.profile-image {
	&:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}
}
</style>
